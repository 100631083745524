/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../stylesheets/application.scss'

console.log("BEGIN: application.js")

import Rails from 'rails-ujs'
Rails.start()

import * as ActiveStorage from "activestorage"
ActiveStorage.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()


// https://github.com/turbolinks/turbolinks/issues/272
// https://thelucid.com/2019/02/20/handling-get-remote-forms-with-turbolinks-in-rails-6
document.addEventListener('turbolinks:load', function(event) {
    for (let form of document.querySelectorAll('form[method=get][data-remote=true]')) {
        form.addEventListener('ajax:beforeSend', function (event) {
            const options = event.detail[1]
            Turbolinks.visit(options.url)
            event.preventDefault()
        })
    }

    // workaround for <a href='#'> tags that do not call preventDefault()
    // in a perfect world, this would be the library/event handler's responsibility
    // turbolinks makes this commonly neglected duty obvious by doing a reload and breaking history
    for (const a of document.querySelectorAll('a[href="#"]')) {
        a.addEventListener('click', event => event.preventDefault())
    }

    if (document.body.dataset.showHelpWidget === 'true') {
        init_zendesk("https://assets.zendesk.com/embeddable_framework/main.js", "moriapp.zendesk.com")
    }
})

// handle 401 unauthorized by doing a full page load to the page specified in the response header
// https://github.com/turbolinks/turbolinks/issues/86

document.addEventListener('turbolinks:request-end', event => {
    if (event.data.xhr.status === 401) {
        window.location = event.data.xhr.getResponseHeader("WWW-Authenticate")
    }
})

document.addEventListener('turbolinks:visit', () => $('.modal').modal('hide'))

import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../src/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

console.log("END: application.js")
