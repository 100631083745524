import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [
        "start", "end"
    ]

    connect () {
        this.start_date_picker = $(this.startTarget).datetimepicker(this._buildOptions())
        this.end_date_picker = $(this.endTarget).datetimepicker(this._buildOptions())

        this.start_date = this.getStartDate()
        this.end_date_picker.datetimepicker('setStartDate', this.start_date)

        this.start_date_picker.on('changeDate', event => {
            console.log("date-range", "start", event)
            this.keepDateRange()
        })
    }

    disconnect () {
        // this.start_date_picker.off('changeDate')
        this.start_date_picker.datetimepicker('remove')
        this.end_date_picker.datetimepicker('remove')
    }

    keepDateRange () {
        const start_date = this.getStartDate()

        const difference = start_date - this.start_date
        this.start_date = start_date
        const end_date = new Date(+this.getEndDate() + difference)

        this.end_date_picker.datetimepicker('setStartDate', start_date)
        this.end_date_picker.datetimepicker('setDate', end_date)
    }

    getStartDate () {
        return this.start_date_picker.datetimepicker('getDate')
    }

    getEndDate () {
        return this.end_date_picker.datetimepicker('getDate')
    }

    _buildOptions () {
        return {
            minView: 2,
            autoclose: true,
            format: 'mm/dd/yyyy',
            bootcssVer: 4,
            navIcons: {
                leftIcon: 'mdi-chevron-left',
                rightIcon: 'mdi-chevron-right',
                iconType: 'mdi'
            },
            todayBtn: true,
            todayHighlight: true
        }
    }
}
