import { Controller } from 'stimulus'
import 'datepair.js'

export default class extends Controller {
    static targets = [
        "start", "end"
    ]

    connect () {
        this.$element = $(this.element)
        this.$date_pickers = this.$element.find('.date').datetimepicker(this._buildDateOptions())
        this.$time_pickers = this.$element.find('.time').timepicker(this._buildTimeOptions())
        this.$time_pickers.on('keydown', this.handleKey.bind(this))

        // ensure the timepicker is attached
        // with Turbo navigation, the body may have changed
        $.TimePicker._instance.container.appendTo(document.body)

        this.datepair = new Datepair(this.element, {
            parseTime: function (input) {
                return $(input).timepicker('getTime')
            },
            updateTime: function (input, date){
                $(input).timepicker('setTime', date)
            },
            setMinTime: function (input, date){
                // timepicker does not handle changing minTime well
                // so this is a no-op
                // $(input).timepicker('option', 'minTime', date)
            },
            parseDate: function (input){
                return input.value && $(input).datetimepicker('getDate')
            },
            updateDate: function (input, date){
                $(input).datetimepicker('setDate', date)
            }
        })

        // Datepair does not listen for the time-change event, so we have to
        this.$time_pickers.on('time-change', event => {
            console.log('time-change', event)
            this.datepair._timeChanged(event.target)
        })
    }

    disconnect () {
        this.datepair.remove()
        this.$date_pickers.datetimepicker('remove')
        this.$time_pickers.timepicker('destroy')
    }

    _buildDateOptions () {
        return {
            minView: 2,
            autoclose: true,
            format: 'mm/dd/yyyy',
            bootcssVer: 4,
            navIcons: {
                leftIcon: 'mdi-chevron-left',
                rightIcon: 'mdi-chevron-right',
                iconType: 'mdi'
            },
            todayBtn: true,
            todayHighlight: true
        }
    }

    _buildTimeOptions () {
        return {
            timeFormat: 'h:mm p',
            // interval: 30,
            minTime: '12:00am',
            maxTime: '11:30pm',
            // defaultTime: '7:30pm',
            startTime: '9:00am',
            dynamic: false,
            // dropdown: true,
            scrollbar: true
        }
    }

    handleKey (event) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            this.scrollIntoView()
            event.preventDefault()
        }
    }

    scrollIntoView () {
        const selected = document.getElementById('ui-active-item')
        if (selected && selected.scrollIntoViewIfNeeded) {
            selected.scrollIntoViewIfNeeded(false)
        }
    }
}
