import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        console.log('carousel-controller#connect')
        const first_slide = this.element.querySelector('.carousel-item')
        this.preloadSiblings(first_slide)

        $(this.element).on('slide.bs.carousel', event => {
            const current_slide = event.relatedTarget
            this.preloadSiblings(current_slide)
        })
    }

    preloadSiblings (slide) {
        if (!slide) return
        const last_slide = slide.previousElementSibling || slide.parentElement.lastElementChild
        this.preload(last_slide)
        const next_slide = slide.nextElementSibling
        this.preload(next_slide)
    }

    preload (slide) {
        if (!slide) return
        const img = slide.querySelector('img')
        if (img && img.loading === 'lazy') {
            console.log('carousel-controller#preload', img)
            img.loading = 'eager'
        }
    }
}
