import { Controller } from 'stimulus'

export default class extends Controller {
    calendar

    connect() {
        const options = this._buildOptions()
        this.calendar = $(this.element).fullCalendar(options)
        console.log("calendar#connect", options, this.calendar)
    }

    disconnect() {
        console.log("calendar#disconnect")
        this.calendar.fullCalendar('destroy')
    }

    _buildOptions() {
        // defaultView values: basicDay,basicWeek,listDay,listWeek,listMonth,listYear,agendaDay,agendaWeek,month
        const { url, defaultView, contentHeight } = this.element.dataset
        const height = this._parseHeight(this.element.dataset.height)
        const eventLimit = this.element.dataset.eventLimit === 'true'
        const days = parseInt(this.element.dataset.days, 10)
        return {
            height,
            contentHeight,
            defaultView,
            eventLimit,
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'today,list,month'
            },
            views: {
                list: {
                    duration: { days },
                    listDayAltFormat: 'dddd'
                }
            },
            events: url,
            timezone: false,
            eventRender: (event, element) => {
                if (this.calendar.fullCalendar('getView').name === 'listYear') {
                    const title = element.find(".fc-list-item-title")
                    title.append("<br />" + event.description)
                }
                if (!event.title.indexOf("Cancel")) {
                    const title = element.find(".fc-list-item-title")
                    title.class("text-danger")
                }
            }
        }
    }

    _parseHeight (str) {
        const int = parseInt(str, 10)
        return int.toString() === str ? int : str
    }
}
